<template>
  <div 
    class="product-card__price-suggested"
    :class="{
      'price-right': flipText,
      'price-left': !flipText
    }"
  >
    <template v-if="flipText">
      <span class="suggested-label">{{ labelText || '' }}:</span>

      <del 
        v-if="priceDeleteStyle" 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</del>
      <span 
        v-else 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</span>
    </template>
    <template v-else>
      <del 
        v-if="priceDeleteStyle" 
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</del>
      <span 
        v-else
        class="suggested-price"
      >{{ value && value.amountWithSymbol }}</span>

      <span class="suggested-label">{{ labelText || '' }}</span>
    </template>
  </div>
</template>

<script>
/**
 * @file 合规价/划线价
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuggestedSalePrice',
  props: {
    value: {
      type: [Object, null],
      required: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    flipText: {
      type: Boolean,
      default: false,
    },
    // 价格是否使用删除线样式
    priceDeleteStyle: {
      type: Boolean,
      default: false,
    }
  },
  setup() {},
})

</script>

<style lang="less" scoped>
.product-card__price-suggested {
  line-height: 1.2em;

  font-size: .266rem;
  font-weight: 400;
  color: #767676;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price-left .suggested-price{
  padding-right: .053rem;
}
</style>
