<script lang="jsx">
import ResizeFont from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFontText.vue'
export default {
  name: 'CamelCasePrice',
  functional: true,
  components: {
    ResizeFont,
    ResizeFontText
  },
  props: {
    // 是否使用驼峰样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    // 驼峰价格是否支持动态字号：显示驼峰价格时，价格显示不下时，驼峰大字号缩小，直到显示小字号，保证价格显示更多
    camelPriceResizeFontsize: {
      type: Boolean,
      default: false,
    },
    // 原始价格字符串
    originString: {
      type: String,
      default: '',
    },
    // 币种符号前缀
    currencySymbolPrefix: {
      type: String,
      default: ''
    },
    // 币种符号后缀
    currencySymbolSuffix: {
      type: String,
      default: ''
    },
    // 小数点前金额
    numberBeforeDot: {
      type: [String, Number],
      default: ''
    },
    // 小数点后金额
    numberAfterDot: {
      type: String,
      default: ''
    },
    // 驼峰价格点前后fontSize
    camelPriceStyle: {
      type: Object,
      default: () => {}
    },
    // salePrice价格样式
    salePriceStyle: {
      type: Object,
      default: () => {}
    },
  },
  render(h, obj) {
    const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix, showCamelPrice, camelPriceResizeFontsize, originString, camelPriceStyle = {}, salePriceStyle = {} } = obj.props || {}
    let camelHtml = (<p class="product-card__camel-case-price" {...obj.data} style={{ ...(camelPriceStyle?.after || {}), ...salePriceStyle }}>
      <span class="product-card__camel-case-price__prefix-symbol">
        {currencySymbolPrefix}
      </span>
      <span class="product-card__camel-case-price__number-before-dot" style={ camelPriceStyle?.before }>
        {numberBeforeDot}
      </span> 
      <span class="product-card__camel-case-price__number-after-dot">
        {numberAfterDot}
      </span>
      <span class="product-card__camel-case-price__suffix-symbol">
        {currencySymbolSuffix}
      </span>
    </p>)
    if (showCamelPrice && camelPriceResizeFontsize) {
      const afterStyle = camelPriceStyle?.after ? JSON.parse(JSON.stringify(camelPriceStyle.after)) : {}
      const beforeStyle = camelPriceStyle?.before ? JSON.parse(JSON.stringify(camelPriceStyle.before)) : {}
      // 宽度为0，透明，仅占位撑高元素高度
      const placeholderStyle = {
        width: 0,
        opacity: 0,
        display: 'inline-block',
      }
      let afterFrontSize = 11 // .453rem
      let beforeFrontSize = 17 // .293rem
      if (showCamelPrice) {
        if (afterStyle?.fontSize) {
          afterFrontSize = afterStyle.fontSize
          if (afterFrontSize.indexOf('px') > -1) {
            afterFrontSize = afterFrontSize.replace('px', '')
          }
          afterFrontSize = Number(afterFrontSize)
        }
        if (beforeStyle?.fontSize) {
          beforeFrontSize = beforeStyle.fontSize
          if (beforeFrontSize.indexOf('px') > -1) {
            beforeFrontSize = beforeFrontSize.replace('px', '')
          }
          beforeFrontSize = Number(beforeFrontSize)
          placeholderStyle.fontSize = beforeStyle.fontSize
          delete beforeStyle.fontSize
        }
      }
      const data = obj.data || {}
      if (data?.style?.fontSize) {
        // 删除字体大小样式，避免影响
        delete data.style.fontSize
      }
      camelHtml = (
        <ResizeFont class="product-card__camel-case-price" {...data} style={{ ...afterStyle, ...salePriceStyle}}>
          {/* 占位元素，大字号，字体不缩小，保证与其他商品价格对其 */}
          <span         
            class="product-card__camel-case-price__placeholder"
            style={ placeholderStyle }
          >
            s
          </span>
          <span         
            class="product-card__camel-case-price__prefix-symbol"
          >
            {currencySymbolPrefix}
          </span>
          <ResizeFontText         
            init-font-size={beforeFrontSize}
            resize-font-min={afterFrontSize}
            class="product-card__camel-case-price__number-before-dot"
            style={ beforeStyle }
          >
            {numberBeforeDot}
          </ResizeFontText>
          <span         
            class="product-card__camel-case-price__number-after-dot"
          >
            {numberAfterDot}
          </span>
          <span         
            class="product-card__camel-case-price__suffix-symbol"
          >
            {currencySymbolSuffix}
          </span>
        </ResizeFont>
      )
    }
    const notCamelPrice = (
      <span class="product-card__no-camel-case-price" {...obj.data}>
        {originString}
      </span>
    )
    const result = showCamelPrice ? camelHtml : notCamelPrice
    return result
  },
}
</script>

<style lang="less">
.product-card {
  &__camel-case-price {
    .flexbox();
    align-items: baseline;
    height: 0.5333rem;
    line-height: 0.5333rem;
    font-size: .293rem;
    /* stylelint-disable-next-line */
    &__number-before-dot {
      font-size: .453rem;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &__no-camel-case-price {
    flex: 1;
    .text-overflow();
  }
  // &__camel-case-price.resize-font {
  //   span {
  //     display: inline-block;
  //   }
  // }
}
</style>
